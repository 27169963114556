import { Dayjs } from "dayjs";
import { Moment } from "moment";

export type Client = {
  id: number;
  name: string;
  logo?: string;
};

export type Branch = {
  id: number;
  name: string;
  client_id: number;
  status: "deleted" | "confirmed";
  billing_address: string;
  billing_address_2: string;
  company_registration_number: string;
  vat_number: string;
  country: string;
  vat_rate: number;
  iban: string;
  invoice_day_limit: number;
};

export type BranchRate = {
  id: number;
  name: string;
  rate: number;
  currency: string;
  location_id: number;
  branch_id: number;
  holiday_rate: number;
  subcontractor_rates_id?: number;
  subcontractor_rates: SubcontractorRate[];
};

export type SubcontractorRate = {
  id: number;
  branch_rate_id: string;
  branch_rate: BranchRate;
  subcontractor_id: number;
  subcontractor: SubContractor;
  rate: number;
  holiday_rate: number;
  currency: string;
};

export type AgentRate = {
  id: number;
  branch_rate_id: string;
  branch_rate: BranchRate;
  agent_id: number;
  rate: number;
  holiday_rate: number;
  currency: string;
};

export type License = {
  id: number;
  name: string;
};
export type Location = {
  id: number;
  name: string;
  longitude: string;
  latitude: string;
  address: string;
  country: string;
  city: string;
  client_id: string;
  branch_id: string;
  timezone: string;
  number: string;
};

export type UserAccount = User;

export type Contact = {
  branch_id: number | null;
  client_id: number | null;
  created_at: string;
  email: string;
  emergency: 0 | 1;
  emergency_bcc: 0 | 1;
  emergency_cc: 0 | 1;
  finance: 0 | 1;
  finance_bcc: 0 | 1;
  finance_cc: 0 | 1;
  id: number;
  name: string;
  note?: string;
  phone_number?: string;
  request_cc: 0 | 1;
  subcontractor_id: string | null;
  updated_at: string;
};
export type Contractor = {
  id: number;
  name: string;
  contact_person: string;
  email: string;
  phone_number: string;
  emergency_phone_number: string;
  address: string;
  vat_number: string;
  company_registration_number: string;
  status: "confirmed" | "deleted";
  created_at: string;
  updated_at: string;
  surcharge_applicable: boolean;
};

export type Notification = {
  data: any[];
};

export type RequestEquipment = {
  equipment: Array<Equipment>;
  branch_id: number;
  currency: string;
  end: string;
  id: number;
  request_id: number;
  start: string;
  subcontractor_id: number;
  subcontractor: Contractor;
};
export type Request = {
  id: number;
  identifier: string;
  po_number: string;
  status: RequestStatus;
  client_id: number;
  branch_id: number;
  location_id: number;
  note: string;
  equipment: Array<RequestEquipment>;
  created_at: string;
  updated_at: string;
  branch: Branch;
  client: Client;
  location: Location;
  request_timeslots: Array<RequestTimeSlot>;
};

export type Holidays = {
  id: number;
  name: string;
  country: string;
  date: string;
  timezone: string;
  surcharge_percentage: number;
};

export type AgendaForm = {
  date_range: Array<Moment>;
  branch_id: number;
  location_id: number;
  activeTab: number;
};

export type Planning = {
  id: number;
  request_id: number;
  request_timeslot_id: number;
  start: string;
  end: string;
  client_id: number;
  branch_id: number;
  subcontractor_id: number | null;
  location_id: number;
  status: string;
  invoice_status: string;
  subcontractor_status: string | null;
  request_type: RequestType;
  number_of_agents: number;
  note: string | null;
  created_at: string;
  updated_at: string;
  shifts: Array<Shift>;
  subcontractor?: Contractor | null;
  request: Request;
  location?: Location;
  branch?: Branch;
  client: Client;
  has_shifts: boolean;
  history: PlanningHistory[];
  branch_rate_id: number;
  extra_branch_rate_id: number;
  extra_hours: number;
  extra_number_of_agents: number;
  request_identifier: number;
  po_number?: string;
  invoice: Invoice;
  invoice_id?: number;
  branch_rate?: BranchRate;
  extra_branch_rate?: BranchRate;
};

export type SubContractor = {
  id: number;
  name: string;
};
export type Agent = {
  id: number;
  first_name: string;
  last_name: string;
  licenses: Array<string>;
  subcontractor_id: number | null;
  user: User;
  subcontractor: Contractor;
  valid_from: string;
  valid_until: string;
  license_number: string;
  blocked: 1 | 0;
  surcharge_applicable: boolean;
};

export type User = {
  agent_id: number | null;
  app_version: string;
  branch_id: number | null;
  client_id: number | null;
  subcontractor_id: number | null;
  created_at: string;
  deleted_at: null | string;
  email: string;
  email_verified_at: string | null;
  entity: "agent" | "admin" | "branch" | "client" | "subcontractor" | "manager";
  first_name: string;
  id: number;
  last_name: string;
  phone_model: string | null;
  phone_number: string;
  phone_platform: string | null;
  phone_version: string | null;
  roles: Array<"agent" | "admin" | "branch" | "client" | "subcontractor">;
  status: number;
  updated_at: string;
  verified: 1 | 0;
  blocked: 1 | 0;
  branch?: Branch;
  subcontractor?: Contractor;
  created_by?: User;
};

export type Invoice = {
  id: number;
  invoice_number: string;
  currency: string;
  data: InvoiceData;
  subcontractor_id: number;
  subcontractor: SubContractor;
  branch_id: number;
  branch: Branch;
  status: "paid" | "sent" | "checked" | "generated";
};

interface InvoiceData {
  billing_address: string;
  billing_address_2?: string;
  branch_id: number;
  client_id: number;
  company_name: string;
  country: string;
  currency: string;
  department: string;
  description: string;
  hide_specification: boolean;
  invoice_date: string;
  invoice_day_limit: number;
  invoice_number: string;
  request_equipment_range: any[]; // Change to the appropriate type if possible
  sent_to: any[]; // Change to the appropriate type if possible
  totalExcl: string;
  totalIncl: string;
  type: string;
  vat: number;
  vat_number: string;
  work: WorkItem[];
}

interface WorkItem {
  total: string;
  service: string;
  currency: string;
  isHoliday: boolean;
  locationName: string;
  planningRates: PlanningRate[];
}

interface PlanningRate {
  end: string;
  price: string;
  start: string;
  total: number;
  amount: number;
  currency: string;
  description: string | null;
  planningId: number;
  quantity: number;
}

export type Shift = {
  key: number;
  id: number;
  start: string;
  end: string;
  subcontractor_id: number;
  planning_id: number;
  agents: Array<Agent & { pivot: AgentShift }>;
  agent_shifts: Array<AgentShift>;
  subcontractor?: Contractor;
  subcontractor_status: "pending" | "confirmed" | "rejected" | null;
  status: string;
  note?: string;
  planning: Planning;
  subcontractor_invoice?: Invoice;
  subcontractor_invoice_id?: number;
  purchase_cost?: string; // for invoice purposes
  purchase_cost_per_hour?: string; // for invoice purposes
  surcharge: number; // for invoice purposes
  currency?: string; // for invoice purposes
  sales_cost_per_hour?: string; // for invoice purposes
  marge_rate?: string; // for invoice purposes
  total_sales_cost?: string; // for invoice purposes
  total_purchase_cost: number; // for invoice purposes
  total_marge: number; // for invoice purposes
  total_marge_percentage: string; // for invoice purposes
  branch_rate_id?: number;
  branch_rate?: BranchRate;
  extra_rate: number;
};

export type AgentShift = {
  agent_id: number;
  id: number;
  latitude: number | null;
  longitude: number | null;
  shift_id: number;
  sos: number | null;
  status: AgentShiftStatus;
  confirm_ended: number;
  agent?: Agent;
};

export enum AgentShiftStatus {
  pending = "pending",
  started = "started",
  ended = "ended",
  confirmed = "confirmed",
}
export type Equipment = {
  id: number;
  name: string;
  quantity?: number;
  price: number;
  equipment_rates: Array<EquipmentRate>;
};

export type EquipmentRate = {
  branch_id: number;
  currency: string;
  equipment_id: number;
  id: number;
  location_id: number;
  rate: number;
  subcontractor_id: number;
};

export type Timeslot = {
  id: number;
  start_time: string;
  end_time: string;
  week_days: Array<WeekDays>;
  number_of_agents: number;
  subcontractor_id: number;
  branch_rate_id: number;
  extra_branch_rate_id: number;
};

export type RequestAction = {
  id: number;
  action: "confirmed" | "rejected";
  timeslots: Array<{
    id: number;
    branch_rate_id: number;
  }>;
  reason: string;
};
export type RequestTimeSlot = {
  id: number;
  request_id: number;
  start: string;
  end: string;
  date_range?: Array<Dayjs>;
  request_type: RequestType | undefined;
  continuous: 0 | 1;
  timeslots: Array<Timeslot>;
  status: RequestStatus;
  reason: string;
  note: string;
  created_at: string;
  updated_at: string;
};

export type UserEntity = {
  ADMIN: "admin";
  CLIENT: "client";
  AGENT: "agent";
  CONTRACTOR: "subcontractor";
};

export enum RequestStatus {
  pending = "pending",
  confirmed = "confirmed",
  cancelled = "cancelled",
}

export type ActionStatus = {
  pending: "pending";
  confirmed: "confirmed";
  finished: "finished";
  rejected: "rejected";
  cancelled: "cancelled";
  archived: "archived";
};

export type RequestType = {
  STATIC: "Static";
};

export enum WeekDays {
  MONDAY = "Monday",
  TUESDAY = "Tuesday",
  WEDNESDAY = "Wednesday",
  THURSDAY = "Thursday",
  FRIDAY = "Friday",
  SATURDAY = "Saturday",
  SUNDAY = "Sunday",
}

export type PlanningHistory = {
  id: number;
  agent_id: number;
  agent: Agent;
  agent_shift_id: number;
  created_at: string;
  note: string;
  planning_id: number;
  request_id: number;
  shift_id: number;
  status: "started" | "ended" | "image" | "break" | "cost" | "note";
  updated_at: string;
  break_finished_at: string;
  break_reason: string;
  cost: number;
  currency: string;
  image: string;
  latitude: number;
  longitude: number;
  tr_note: string;
  tr_reason: string;
};
