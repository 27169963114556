import { Holidays, Planning, Shift } from "../../../redux/apis/types";
import moment from "moment/moment";
import actions from "../../../redux/planning/actions";
import { DiffHours, timeZoneMoment } from "../../../redux/apis/utils";
import { calculateSurcharge } from "./surchargeUtils";

export const getInvoiceShiftClientRate = (planning: Planning, hours = 1) => {
  if (!planning.invoice_id && planning?.branch_rate?.rate) {
    return (
      ((planning?.branch_rate?.rate * hours) / 100).toFixed(2) +
      " " +
      planning?.branch_rate?.currency
    );
  }
  const work = planning.invoice?.data?.work || [];

  for (let i = 0; i < work.length; i++) {
    const planningRate = work[i].planningRates.find(
      (item) => item.planningId === planning.id
    );
    if (planningRate) {
      return planningRate?.price
        ? (parseFloat(planningRate.price) * hours).toFixed(2) +
            " " +
            planningRate.currency
        : 0 + "";
    }
  }

  return 0 + "";
};

export const getInvoiceShiftSubcRate = (shift: Shift, hours = 1) => {
  const planning = shift.planning;
  if (
    !shift.subcontractor_invoice_id &&
    planning?.branch_rate?.subcontractor_rates &&
    planning?.branch_rate?.subcontractor_rates.length > 0 &&
    planning?.branch_rate_id
  ) {
    const subcRate = planning?.branch_rate?.subcontractor_rates.find(
      (item) =>
        item.branch_rate_id + "" === planning.branch_rate_id + "" &&
        shift.subcontractor_id === item.subcontractor_id
    );
    if (subcRate) {
      return (
        ((subcRate?.rate * hours) / 100).toFixed(2) + " " + subcRate?.currency
      );
    }
  }

  if (shift.subcontractor_invoice_id) {
    const invoiceShift: any = shift.subcontractor_invoice?.data?.work?.find(
      (item: any) => item.id === shift.id
    );

    return (
      (parseFloat(invoiceShift?.purchase_cost) * hours)?.toFixed(2) +
        " " +
        shift.subcontractor_invoice?.currency || "N/A"
    );
  }

  // for (let i = 0; i < work.length; i++) {
  //   const planningRate = work[i].planningRates.find(
  //     (item) => item.planningId === planning.id
  //   );
  //   return planningRate?.price
  //     ? parseFloat(planningRate.price) * hours + " " + planningRate.currency
  //     : "N/A";
  // }

  return "N/A";
};

export const MargeRate = (
  record: Shift,
  hours: number = 1,
  purchaseCost?: string
) => {
  const branchRate = getInvoiceShiftClientRate(record.planning, hours);

  const subcRate = purchaseCost
    ? parseFloat(purchaseCost) * hours
    : getInvoiceShiftSubcRate(record, hours);
  if (branchRate !== "N/A" && subcRate !== "N/A") {
    return (
      parseFloat(branchRate) -
      parseFloat(record.purchase_cost || (subcRate as string))
    ).toFixed(2);
  }

  return 0;
};
export const MargePercentage = (
  record: Shift,
  hours: number,
  purchaseCost?: string
) => {
  const branchRate = getInvoiceShiftClientRate(record.planning, hours);
  const subcRate =
    purchaseCost !== undefined
      ? parseFloat(purchaseCost) * hours
      : getInvoiceShiftSubcRate(record, hours);
  if (branchRate !== "N/A" && subcRate !== "N/A") {
    return (
      (
        ((parseFloat(branchRate) - parseFloat(purchaseCost as string)) /
          parseFloat(branchRate)) *
        100
      ).toFixed(2) + "%"
    );
  }

  return "N/A";
};

export const calculateTotalMargeWithSurcharge = (
  record: Shift,
  holidays: Holidays[],
  timeZoneMoment: (date: string, timezone: string) => moment.Moment
): { totalMarge: number; currency: string } => {
  const hours = parseFloat(DiffHours(record.start, record.end).toFixed(2));
  const salesCost = parseFloat(
    getInvoiceShiftClientRate(record.planning, hours).split(" ")[0] || "0"
  );
  const purchaseCost = parseFloat(
    getInvoiceShiftSubcRate(record, DiffHours(record.start, record.end))
  );
  const { surcharge } = calculateSurcharge(record, holidays);
  const totalMarge = salesCost - (purchaseCost + surcharge);
  return {
    totalMarge,
    currency: record.planning?.branch_rate?.currency || "EUR",
  };
};

export const calculateMargePercentage = (
  record: Shift,
  holidays: Holidays[],
  timeZoneMoment: (date: string, timezone: string) => moment.Moment
): { margePercentage: string } => {
  const hours = parseFloat(DiffHours(record.start, record.end).toFixed(2));
  const salesCost = parseFloat(
    getInvoiceShiftClientRate(record.planning, hours).split(" ")[0] || "0"
  );
  const purchaseCost = parseFloat(
    getInvoiceShiftSubcRate(record, hours) || "0"
  );
  const { surcharge } = calculateSurcharge(record, holidays);
  let margePercentage = "N/A";
  if (salesCost > 0 && purchaseCost > 0) {
    margePercentage =
      (((salesCost - (purchaseCost + surcharge)) / salesCost) * 100).toFixed(
        2
      ) + "%";
  }

  return {
    margePercentage,
  };
};
