import { Agent, Holidays, Planning, Shift } from "../../../redux/apis/types";
import moment, { Moment } from "moment";
import {
  getInvoiceShiftClientRate,
  getInvoiceShiftSubcRate,
  MargeRate,
} from "./invoiceUtils";
import { DiffHours, timeZoneMoment } from "../../../redux/apis/utils";
import { any } from "prop-types";
import { get, SERVICES } from "../../../redux/apis/global";

const weekendSurchargeRate = 35;
const nightSurchargeRate = 20;
const eveningSurchargeRate = 10;
const holidaySurchargeRate = 100;

const specialHolidays = [
  { date: "12-31", startHour: 16, endHour: 24 },
  { date: "01-01", startHour: 0, endHour: 16 },
];

const belgiumSurchargeMap = {
  holiday: {
    weekday: {
      day: 30,
      evening: 42,
      night: 52.5,
    },
    saturday: {
      day: 48,
      evening: 60,
      night: 70,
    },
    sunday: {
      day: 53,
      evening: 65,
      night: 75.5,
    },
  },
  regular: {
    weekday: {
      day: 0,
      evening: 12,
      night: 22.5,
    },
    saturday: {
      day: 18,
      evening: 30,
      night: 40.5,
    },
    sunday: {
      day: 23,
      evening: 35,
      night: 45.5,
    },
  },
};

export const isWeekend = (date: Moment) =>
  date.isoWeekday() === 6 || date.isoWeekday() === 7;
export const isSaturday = (date: Moment) => date.isoWeekday() === 6;
export const isSunday = (date: Moment) => date.isoWeekday() === 7;

export const calculateSurcharge = (
  record: Shift,
  holidays: Holidays[]
): { surcharge: number; currency: string } => {
  const { start, end, planning } = record;
  const country = planning?.location?.country || "Unknown";
  const timezone = planning?.location?.timezone || "UTC";
  const currency = planning?.branch_rate?.currency || "EUR";
  const hours = parseFloat(DiffHours(start, end).toFixed(2));
  const purchaseCost = parseFloat(getInvoiceShiftSubcRate(record, hours));
  if (!holidays || !purchaseCost) return { surcharge: 0, currency };

  const isAgentSurchargeApplicable = record.agent_shifts.some(
    (agentShift) => agentShift.agent?.surcharge_applicable
  );
  let surcharge = 0;

  if (
    record.subcontractor?.surcharge_applicable === true ||
    isAgentSurchargeApplicable
  ) {
    if (country === "Netherlands") {
      const details = calculateHourlyDetailsNetherlands(
        start,
        end,
        holidays,
        timezone
      );
      surcharge = parseFloat(
        calculateSurchargeFromHourlyDetails(
          details,
          purchaseCost,
          currency,
          hours
        )
      );
    }
    if (country === "Belgium") {
      const details = calculateHourlyDetailsBelgium(
        start,
        end,
        holidays,
        timezone,
        timeZoneMoment
      );
      surcharge = parseFloat(
        calculateSurchargeFromHourlyDetails(
          details,
          purchaseCost,
          currency,
          hours
        )
      );
    }
  } else {
    surcharge = parseFloat(
      calculateOtherHolidaySurcharge(
        start,
        end,
        holidays,
        purchaseCost,
        timezone,
        currency,
        planning,
        hours
      ).split(" ")[0] || "0"
    );
  }

  return { surcharge, currency };
};

type DayType = "weekday" | "saturday" | "sunday";
type TimeType = "day" | "evening" | "night";
export const getDayTimeType = (
  current: Moment
): {
  dayType: DayType;
  timeType: TimeType;
} => {
  const hour = current.hours();
  let dayType: DayType = "weekday";
  let timeType: TimeType = "night";

  if (isSaturday(current)) {
    dayType = "saturday";
  } else if (isSunday(current)) {
    dayType = "sunday";
  }

  if (hour >= 6 && hour <= 20) {
    timeType = "day";
  } else if (hour > 20 && hour <= 22) {
    timeType = "evening";
  }

  return { dayType, timeType };
};

export const calculateHourlyDetailsBelgium = (
  start: string,
  end: string,
  holidays: Holidays[],
  timezone: string,
  timeZoneMoment: (date: string, timezone: string) => moment.Moment
): { duration: number; type: string; surcharge_percentage: number }[] => {
  const startTime = timeZoneMoment(start, timezone);
  const endTime = timeZoneMoment(end, timezone);
  const hourlyDetails: {
    duration: number;
    type: string;
    surcharge_percentage: number;
  }[] = [];
  let current = moment(startTime);

  while (current.isBefore(endTime)) {
    const nextHour = moment(current).add(1, "hour");
    const hourDuration = nextHour.isAfter(endTime)
      ? endTime.diff(current, "minutes") / 60
      : 1;
    let surcharge_percentage = 0;

    const matchingHoliday = holidays.find(
      (holiday) =>
        timeZoneMoment(holiday.date, timezone).isSame(current, "day") &&
        holiday.country === "Belgium"
    );

    let surchargeMap = matchingHoliday
      ? belgiumSurchargeMap.holiday
      : belgiumSurchargeMap.regular;

    const dayTimeType = getDayTimeType(current);
    surcharge_percentage =
      surchargeMap[dayTimeType.dayType][dayTimeType.timeType];

    hourlyDetails.push({
      duration: hourDuration,
      type: dayTimeType.dayType + " - " + dayTimeType.timeType,
      surcharge_percentage,
    });

    current = nextHour;
  }

  return hourlyDetails;
};
export const calculateHourlyDetailsNetherlands = (
  start: string,
  end: string,
  holidays: Holidays[],
  timezone: string
): { duration: number; type: string; surcharge_percentage: number }[] => {
  const startTime = timeZoneMoment(start, timezone);
  const endTime = timeZoneMoment(end, timezone);
  const hourlyDetails: {
    duration: number;
    type: string;
    surcharge_percentage: number;
  }[] = [];
  let current = moment(startTime);

  while (current.isBefore(endTime)) {
    const nextHour = moment(current).add(1, "hour");
    const hourDuration = nextHour.isAfter(endTime)
      ? endTime.diff(current, "minutes") / 60
      : 1;
    const hour = current.hours();
    let type = "regular";
    let surcharge_percentage = 0;
    const matchingSpecialHoliday = specialHolidays.find((special) => {
      const dateMatch = current.format("MM-DD") === special.date;
      const hourMatch = hour >= special.startHour && hour < special.endHour;
      return dateMatch && hourMatch;
    });

    if (matchingSpecialHoliday) {
      const matchingHoliday = holidays.find((holiday) =>
        timeZoneMoment(holiday.date, timezone).isSame(current, "day")
      );

      if (matchingHoliday) {
        type = "specialHoliday";
        surcharge_percentage = matchingHoliday.surcharge_percentage; // Pobieramy stawkę z `holidays`
      }
    } else {
      const isExcludedHoliday = specialHolidays.some(
        (special) => current.format("MM-DD") === special.date
      );

      if (!isExcludedHoliday) {
        const matchingHoliday = holidays.find(
          (holiday) =>
            timeZoneMoment(holiday.date, timezone).isSame(current, "day") &&
            holiday.country === "Belgium"
        );

        if (matchingHoliday) {
          type = "holiday";
          surcharge_percentage = matchingHoliday.surcharge_percentage;
        }
      }

      if (type === "regular") {
        if (isWeekend(current)) {
          type = "weekend";
          surcharge_percentage = weekendSurchargeRate;
        } else if (hour < 7) {
          type = "night";
          surcharge_percentage = nightSurchargeRate;
        } else if (hour >= 18 && hour < 24) {
          type = "evening";
          surcharge_percentage = eveningSurchargeRate;
        }
      }
    }

    hourlyDetails.push({
      duration: hourDuration,
      type,
      surcharge_percentage,
    });

    current = nextHour;
  }

  return hourlyDetails;
};

export const calculateSurchargeFromHourlyDetails = (
  hourlyDetails: {
    duration: number;
    type: string;
    surcharge_percentage: number;
  }[],
  purchaseCost: number,
  currency: string,
  hours: number
): string => {
  if (hourlyDetails.length === 0) {
    return `0 ${currency}`;
  }

  const costPerHour = purchaseCost / hours;
  const totalSurchargeValue = hourlyDetails.reduce(
    (total, { duration, surcharge_percentage }) => {
      const surchargeForHour =
        costPerHour * duration * (surcharge_percentage / 100);
      return total + surchargeForHour;
    },
    0
  );
  return `${totalSurchargeValue.toFixed(2)} ${currency}`;
};

export const calculateHourlyRange = (
  start: string,
  end: string,
  holidays: Holidays[],
  timezone: string,
  timeZoneMoment: (date: string, timezone: string) => moment.Moment
): {
  range: Record<string, number>;
  holidayRates: { type: string; rate: number }[];
} => {
  const startTime = timeZoneMoment(start, timezone);
  const endTime = timeZoneMoment(end, timezone);
  const range = {
    weekend: 0,
    holiday: 0,
    specialHoliday: 0,
    night: 0,
    evening: 0,
  };
  const holidayRates: { type: string; rate: number }[] = [];
  let current = moment(startTime);
  while (current.isBefore(endTime)) {
    const nextHour = moment(current).add(1, "hour");
    const hourDuration = nextHour.isAfter(endTime)
      ? endTime.diff(current, "minutes") / 60
      : 1;
    const hour = current.hours();
    const isWeekend = current.isoWeekday() === 6 || current.isoWeekday() === 7;
    const isSpecialHoliday = specialHolidays?.some((special) => {
      const dateMatch = current.format("MM-DD") === special.date;
      const hourMatch = hour >= special.startHour && hour < special.endHour;

      return dateMatch && hourMatch;
    });

    if (isSpecialHoliday) {
      range.specialHoliday += hourDuration;
    } else {
      const matchingHoliday = holidays.find((holiday) =>
        timeZoneMoment(holiday.date, timezone).isSame(current, "day")
      );

      if (matchingHoliday) {
        range.holiday += hourDuration;
        holidayRates.push({
          type: "holiday",
          rate: matchingHoliday.surcharge_percentage,
        });
      } else {
        if (isWeekend) {
          range.weekend += hourDuration;
        } else if (hour < 7) {
          range.night += hourDuration;
        } else if (hour >= 18 && hour < 24) {
          range.evening += hourDuration;
        }
      }
    }
    current = nextHour;
  }
  return { range, holidayRates };
};

export const calculateSurchargeFromRange = (
  range: Record<string, number>,
  holidayRates: { type: string; rate: number }[],
  purchaseCost: number,
  currency: string,
  hours: number
): string => {
  const totalHours =
    range.weekend +
    range.holiday +
    range.specialHoliday +
    range.night +
    range.evening;

  if (totalHours === 0) {
    return `0 ${currency}`;
  }

  const holidayRateSum = holidayRates
    .filter(({ type }) => type === "holiday")
    .reduce((acc, { rate }) => acc + rate, 0);
  const totalSurchargeValue =
    (purchaseCost / hours) * range.weekend * (weekendSurchargeRate / 100) +
    (purchaseCost / hours) * range.night * (nightSurchargeRate / 100) +
    (purchaseCost / hours) * range.evening * (eveningSurchargeRate / 100) +
    (purchaseCost / hours) * range.holiday * (holidaySurchargeRate / 100) +
    (purchaseCost / hours) *
      range.specialHoliday *
      (holidaySurchargeRate / 100);

  return `${totalSurchargeValue.toFixed(2)} ${currency}`;
};

const fetchHolidays = async () => {
  try {
    const resp = await get(SERVICES.HOLIDAYS);
    if (resp) {
      console.log("Holidays API response:", resp);
      return resp;
    }
  } catch (error) {
    console.error("Error fetching holidays:", error);
    return [];
  }
};

export const getSurchargeData = async (shifts: Shift[]) => {
  const holidays = await fetchHolidays();
  if (!holidays || holidays.length === 0) {
    return shifts;
  }

  return shifts.map((item) => {
    const { surcharge, currency } = calculateSurcharge(item, holidays);

    const purchaseCostPerHour = parseFloat(getInvoiceShiftSubcRate(item));
    const purchaseCost = parseFloat(
      getInvoiceShiftSubcRate(item, DiffHours(item.start, item.end))
    );
    const salesCostPerHour = parseFloat(
      getInvoiceShiftClientRate(item.planning)
    );
    const totalSalesCost = getInvoiceShiftClientRate(
      item.planning,
      DiffHours(item.start, item.end)
    );
    const totalPurchaseCost = purchaseCost + surcharge;
    if (item.id == 33140) {
      console.log("item : ", item);
      console.log("salesCost : ", totalSalesCost);
      console.log("totalPurchaseCost : ", totalPurchaseCost);
    }

    const totalMarge = parseFloat(totalSalesCost) - totalPurchaseCost;
    let margePercentage = "";

    if (salesCostPerHour > 0 && purchaseCostPerHour > 0) {
      margePercentage =
        ((totalMarge / parseFloat(totalSalesCost)) * 100).toFixed(2) + "%";
    }

    return {
      ...item,
      key: item.id,
      sales_cost_per_hour: salesCostPerHour,
      purchase_cost_per_hour: purchaseCostPerHour,
      total_purchase_cost: totalPurchaseCost,
      marge_rate: MargeRate(item, 1),
      total_sales_cost: totalSalesCost,
      purchase_cost: getInvoiceShiftSubcRate(
        item,
        DiffHours(item.start, item.end)
      ),
      surcharge,
      currency,
      total_marge: totalMarge,
      total_marge_percentage: margePercentage,
    };
  });
};

export const calculateOtherHolidaySurcharge = (
  start: string,
  end: string,
  holidays: Holidays[],
  purchaseCost: number,
  timezone: string,
  currency: string,
  planning: Planning,
  hours: number
): string => {
  const startTime = timeZoneMoment(start, timezone);
  const endTime = timeZoneMoment(end, timezone);

  let surcharge_percentage = 0;

  const matchingHoliday = holidays.find(
    (holiday) =>
      (timeZoneMoment(holiday.date, timezone).isSame(startTime, "day") ||
        timeZoneMoment(holiday.date, timezone).isSame(endTime, "day")) &&
      holiday.country === planning?.location?.country
  );

  if (matchingHoliday && planning?.branch_rate?.subcontractor_rates) {
    const subcontractorRate = planning.branch_rate.subcontractor_rates.find(
      (rate: any) => rate.subcontractor_id === planning.subcontractor_id
    );

    if (subcontractorRate) {
      surcharge_percentage = subcontractorRate.holiday_rate || 0;
    }
  }

  const surcharge = purchaseCost * (surcharge_percentage / 100);

  return `${surcharge.toFixed(2)} ${currency}`;
};
