import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SERVICES, get, destroy, update } from "../../../redux/apis/global";
import { TableViews } from "../../Tables/AntTables/AntTables";
import { Button, Space, Input, Row, Col, Tag, Select } from "antd";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Holidays } from "../../../redux/apis/types";
import Modals from "../../../components/Feedback/Modal";
import { State } from "../../../redux/root-reducer";
import moment from "moment-timezone";
import { FormProvider, useForm } from "react-hook-form";
import SelectController from "../../../form/SelectController";
import RangePickerController from "../../../form/RangePickerController";
import { formatPrice } from "../clients/invoice/invoiceUtils";
import { InputSearch } from "../../../components/uielements/input";
import SubcontractorInvoiceModal from "../clients/invoice/SubcontractorInvoiceModal";

import _ from "lodash";
import dayjs from "dayjs";
import { useFilterInvoice } from "./useFilterInvoice";
export const getTagColor = (status: string) => {
  switch (status) {
    case "generated":
      return "black";
    case "sent":
      return "warning";
    case "checked":
      return "processing";
    case "paid":
      return "success";
    case "error":
      return "error";
    default:
      return "black";
  }
};
const IncomingInvoiceList = () => {
  const [subcInvoiceVisible, setsubcInvoiceVisible] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState();
  const { contractors } = useSelector((state: State) => state.Contractors);

  const [searchText, setSearchText] = useState("");
  const [invoices, setInvoices] = useState<any>([]);

  const [search, setSearch] = useState<string[]>([]);
  const [filterData, setFilterData] = useState<any[] | null>(null);

  const methods = useForm<any>({
    defaultValues: {
      date_range: [dayjs().add(-1, "M"), dayjs()],
    },
    mode: "onChange",
  });

  const invoiceFilter = useFilterInvoice(invoices, search);

  const { watch, handleSubmit } = methods;

  useEffect(() => {
    onSubmit({
      date_range: [dayjs().add(-1, "M"), dayjs()],
    });
  }, []);

  const onSubmit = async (data: any) => {
    const resp = await get(SERVICES.INVOICE, 0, {
      from: data.date_range[0].format("YYYY-MM-DD 00:00"),
      to: data.date_range[1].format("YYYY-MM-DD 23:59"),
      // branch: data.branch_id,
      status: data.status,
      type: "subcontractor",
    });

    if (resp) {
      setInvoices(resp);
    } else {
      setInvoices([]);
    }
  };

  useEffect(() => {
    // setSearchText("");
    const subscription = watch(handleSubmit(onSubmit));
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  // const searchHolidays = useMemo(() => {
  //   if (!!country) return holidays.filter((item) => item.country == country);

  //   return holidays;
  // }, [country, holidays]);

  // useEffect(() => {
  //   if (year) {
  //     getData({
  //       start: `${year}-01-01 00:00:00`,
  //       end: `${year}-12-31 23:59:59`,
  //     });
  //   } else {
  //     getData();
  //   }

  //   async function getData(params?: any) {
  //     const resp = await get(SERVICES.HOLIDAYS, undefined, params);
  //     if (resp) dispatch(actions.setHolidays(resp));
  //   }
  // }, [year]);

  const onConfirmDelete = async (id: number) => {
    const response = await destroy(SERVICES.INVOICE, id);
    if (response) {
      setInvoices(
        invoices.filter((invoice: any) => invoice.invoice_number !== id)
      );
    }
  };

  const onDelete = (data: any) => {
    Modals.confirm({
      title: "Want to delete the invoice " + data.invoice_number,
      onOk() {
        onConfirmDelete(data.invoice_number);
      },
      onCancel() {},
      okText: "Confirm",
      cancelText: "Cancel",
    });
  };

  const onSearch = (value: string[]) => {
    setSearch(value);
    // const filterTable = invoices.filter((o: any) =>
    //   Object.keys(o).some((k) =>
    //     String(o[k]).toLowerCase().includes(value.toLowerCase())
    //   )
    // );

    // setFilterData(filterTable);
  };

  const onPressPaid = (invoice: any) => {
    Modals.confirm({
      title: `Are you sure you paid the invoice?`,
      async onOk() {
        const response = await update(
          `${SERVICES.INVOICE}/paid`,
          invoice.id,
          null
        );
        if (response) {
          handleSubmit(onSubmit)();
        }
      },
      onCancel() {},
      okText: "Yes",
      cancelText: "Cancel",
    });
  };

  const columns = [
    {
      title: "Nbr",
      dataIndex: "invoice_number",
    },
    {
      title: "Total Exl",
      dataIndex: ["branch", "name"],
      render: (text: string, record: any) => (
        <div style={{ fontWeight: "bold" }}>
          {formatPrice(
            record?.data?.amount || 0,
            record.currency,
            record.type === "credit" ? true : false
          ) || "N/A"}
        </div>
      ),
    },
    {
      title: "VAT",
      dataIndex: ["branch", "name"],
      render: (text: string, record: any) => (
        <div style={{ fontWeight: "bold" }}>
          {record?.data.vat ? record?.data.vat + "%" : "N/A"}
        </div>
      ),
    },
    {
      title: "Total Inc",
      render: (text: string, record: any) => (
        <div style={{ fontWeight: "bold" }}>
          {formatPrice(
            record.total || 0,
            record.currency,
            record.type === "credit" ? true : false
          ) || "N/A"}
        </div>
      ),
    },
    {
      title: "Date",
      render: (text: string, record: any) => (
        <div>
          {moment(record.invoice_date || record.created_at).format(
            "DD/MM/YYYY"
          )}
        </div>
      ),
    },
    {
      title: "Subcontractor",
      render: (text: string, record: any) => (
        <div>{record.subcontractor?.name || "N/A"} </div>
      ),
    },
    {
      title: "Status",
      render: (text: string, record: any) => (
        <div>
          <Tag color={getTagColor(record.status)}>{record.status}</Tag>
        </div>
      ),
    },

    {
      title: "Actions",
      render: (text: string, record: any) => (
        <Space size="middle">
          {record.status !== "paid" && (
            <Button type="primary" onClick={() => onPressPaid(record)}>
              Paid
            </Button>
          )}
          <Button
            onClick={() => {
              setSelectedInvoice(record);
              setsubcInvoiceVisible(true);
            }}
          >
            <EditFilled />
          </Button>
          <Button danger onClick={() => onDelete(record)}>
            <DeleteFilled />
          </Button>
        </Space>
      ),
    },
  ];

  const onSearchByInvoiceNumber = async (invoice_number: string) => {
    setSearchText(invoice_number);
    if (invoice_number) {
      const resp: any = await get(`${SERVICES.INVOICE}`, invoice_number, null);
      if (resp) {
        setInvoices([resp]);
      } else {
        setInvoices([]);
      }
    } else {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <div>
      <Container>
        {/* <PageHeader buttonLabel="Holidays" onClick={() => setVisible(true)}>
          New Holiday
        </PageHeader> */}
        <FormProvider {...methods}>
          <Row gutter={[20, 10]}>
            <Col>
              <section>
                <RangePickerController
                  rules={{ required: true }}
                  name="date_range"
                  label="Date range"
                />
              </section>
            </Col>
            {/* <Col md={5} sm={24} xs={24}>
              <SelectController
                label="Branches"
                name="branch_id"
                optionKey="id"
                optionValue="name"
                options={branches}
                placeholder="Branches"
              />
            </Col> */}
            <Col md={5} sm={24} xs={24}>
              <SelectController
                label="Subcontractor"
                name="subcontractor_id"
                optionKey="id"
                optionValue="name"
                options={contractors}
                placeholder="Subcontractor"
              />
            </Col>
            {/* <Col md={5} sm={24} xs={24}>
              <div>
                <b>Invoice number</b>
              </div>
              <InputSearch
                type="number"
                enterButton={false}
                // value={searchText}
                loading={false}
                placeholder="invoice number"
                onSearch={onSearchByInvoiceNumber}
              />
            </Col> */}
          </Row>
        </FormProvider>
        <section style={{ marginTop: 10 }}>
          <Select
            mode="tags"
            style={{ width: "100%" }}
            placeholder="search by subcontractor name, invoice number"
            notFoundContent={<div>multiple search</div>}
            onChange={onSearch}
            options={[]}
          />
        </section>

        {/* <Input.Search
          style={{ marginTop: 20 }}
          placeholder="search here.."
          enterButton
          onChange={(event) => onSearch(event.target.value)}
          // onSearch={onSearch}
        /> */}

        <TableViews.SimpleView
          pagination={{
            total: invoiceFilter.length,
          }}
          dataSource={invoiceFilter}
          columns={columns}
        />

        {subcInvoiceVisible && selectedInvoice && (
          <SubcontractorInvoiceModal
            data={selectedInvoice}
            modalVisible={subcInvoiceVisible}
            onClose={() => setsubcInvoiceVisible(false)}
            onCreate={(data: any) => {
              setsubcInvoiceVisible(false);
              setSelectedInvoice(data);
            }}
          />
        )}
      </Container>
    </div>
  );
};

export default IncomingInvoiceList;

const Container = styled.div`
  margin: 20px;
`;
